import * as React from "react"
import Layout from "@lekoarts/gatsby-theme-minimal-blog/src/components/layout"

const NotFound = () => (
  <Layout>
    <h1>404</h1>
    <h2>Page not found</h2>
  </Layout>
)

export default NotFound
